var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subcription"},[_c('c-heading',{attrs:{"fontSize":{ base: 'sm', lg: '2xl', xl: '4xl' },"as":"h1","display":"flex","alignItems":"baseline","mb":"4"}},[_c('c-text',{attrs:{"mr":"3"}},[_vm._v(" Choose plan to continue ")])],1),_c('c-box',{attrs:{"d":"flex","justify-content":"flex-end"},on:{"click":function($event){_vm.showCurrentPlanDetails = true}}},[_c('c-button',{staticClass:"hover_btn",attrs:{"size":"md","px":"8","variant-color":"vc-orange","variant":"outline"}},[_vm._v(" View Subscription ")])],1),_c('c-box',{staticClass:"pricing",attrs:{"d":"flex","flex-direction":{ base: 'row', md: 'row', sm: 'column', xs: 'column' },"mb":"10"}},_vm._l((_vm.plans),function(plan,ix){return _c('c-box',{key:ix,staticClass:"pricing__item",attrs:{"w":{ base: `${100 / 3}%`, md: `${100 / 3}%`, sm: '100%', xs: '100%' },"mx":{
        base: ix === 1 ? '8' : '',
        md: ix === 1 ? '8' : '',
        sm: ' ',
        xs: ' ',
      },"py":"4","boxShadow":"lg"}},[_c('c-box',{staticClass:"item-header",attrs:{"px":"5","borderBottom":"1px","borderBottomColor":"gray.200"}},[_c('c-text',{attrs:{"d":"flex","align-items":"center","color":"#ef5323","fontSize":"sm","mb":"2"}},[_vm._v(_vm._s(plan.title)+" "),(_vm.isCurrentPlan(plan))?_c('c-text',{attrs:{"ml":"3","color":"black","bg":"gray.200","px":"3","py":"1","fontSize":"xs","rounded":"md"}},[_vm._v("Current Personal Plan")]):_vm._e()],1),_c('c-text',{attrs:{"fontWeight":"bold","mb":"3","fontSize":"md"}},[_vm._v(_vm._s(plan.description))]),_c('c-text',{attrs:{"fontSize":"sm","mb":"6"}},[_vm._v(_vm._s(plan.stage))]),_c('c-text',{attrs:{"fontWeight":"bold","fontSize":"md"}},[_vm._v("$"+_vm._s(plan.isMonthly ? plan.monthlyPrice.price / 100 : plan.yearlyPrice.price / 100))]),_c('c-text',{attrs:{"fontSize":"xs","mb":"6"}},[_vm._v(_vm._s(plan.isMonthly ? 'per month' : 'per year'))]),_c('div',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"mb":"6"}},[(!_vm.isFreePlan(plan))?_c('label',{staticClass:"toggle-button",attrs:{"for":`toggle-example-checked-${ix}`}},[_c('input',{attrs:{"type":"checkbox","id":`toggle-example-checked-${ix}`},domProps:{"checked":!plan.isMonthly},on:{"change":function($event){plan.isMonthly = !plan.isMonthly}}}),_c('div',{staticClass:"toggle-bg"}),_c('c-text',{attrs:{"mx":"2","fontSize":"xs","d":"flex","color":"#ef5323"}},[_vm._v("Yearly pricing /  "),_c('c-text',{attrs:{"color":"black"}},[_vm._v("save 15%")])],1)],1):_vm._e()]),_c('c-button',{attrs:{"cursor":"pointer","variant-color":"vc-orange","mb":"6","disabled":_vm.isCurrentPlan(plan) || _vm.isFreePlan(plan)},on:{"click":function($event){return _vm.subscribe(plan)}}},[_vm._v(" Get started ")])],1),_c('c-box',{staticClass:"item-body",attrs:{"px":"5","mt":"8"}},_vm._l((plan.items),function(item,idx){return _c('c-box',{key:idx,attrs:{"d":"flex","mb":"3"}},[_c('c-box',{attrs:{"h":"2em","w":"2em","mr":"3"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                height: '1.5em',
                width: '1em',
              }),expression:"{\n                height: '1.5em',\n                width: '1em',\n              }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/check-circle.svg") + "#check"}})])]),_c('div',[_c('c-text',{attrs:{"fontSize":".9em"}},[_vm._v(_vm._s(item.title))]),(item.type === 'list')?_c('c-text',{attrs:{"fontSize":"0.8em","color":"gray.700"}},[_vm._v(" "+_vm._s(item.subItems.join(', '))+" ")]):_vm._e(),(item.type === 'bullets')?_c('c-box',_vm._l((item.subItems),function(subItem,indx){return _c('div',{key:indx},[_c('c-text',{attrs:{"fontSize":".8em"}},[_vm._v(_vm._s(subItem.title))]),_c('ul',_vm._l((subItem.items),function(listItem,i){return _c('li',{key:i,staticStyle:{"list-style":"none"}},[_c('c-text',{attrs:{"fontSize":".8em"}},[_vm._v(_vm._s(listItem))])],1)}),0)],1)}),0):_vm._e()],1)],1)}),1)],1)}),1),_c('c-modal',{attrs:{"is-open":_vm.showCurrentPlanDetails,"on-close":_vm.closeCurrentPlanDetails,"closeOnOverlayClick":false}},[_c('c-modal-content',{ref:"content"},[_c('c-modal-header',[_vm._v("Your Current Plan")]),_c('c-modal-close-button'),_c('c-modal-body',[_c('c-box',{attrs:{"py":"3"}},[_c('c-text',{attrs:{"d":"flex","align-items":"center","color":"#ef5323","fontSize":"xs","mb":"2"}},[_vm._v("You're on "+_vm._s(_vm.activeSub.name)+" plan ")]),_c('c-text',{attrs:{"mb":"3","fontSize":"md","fontWeight":"bold"}},[_vm._v(_vm._s(_vm.activeSub.description))]),(_vm.activeSub.name !== 'Free')?_c('c-text',{attrs:{"mb":"3","fontSize":".8em","color":"gray.500"}},[_vm._v(" Next payment is due on "+_vm._s(_vm.activeSub.nextBillingDate)+" "),_c('br'),_c('b',[_vm._v(" $"+_vm._s(_vm.activeSub.price / 100)+" USD "+_vm._s(_vm.activeSub.frequency)+" ")])]):_vm._e()],1)],1),_c('c-modal-footer',[(_vm.activeSub.name !== 'Free')?_c('c-button',{attrs:{"variant-color":"vc-orange","mr":"3"},on:{"click":_vm.onEndPlanClick}},[_vm._v(" End Plan ")]):_vm._e()],1)],1),_c('c-modal-overlay')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }